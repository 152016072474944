'use client';

import Image from 'next/image';
import { Button } from '@repo/ui';
import styles from './styles/PageNotFound.module.scss';

export default function ErrorPage() {
    return (
        <div className={styles['page-not-found']}>
            <div className={styles['page-not-found-content']}>
                <Image
                    width={940}
                    height={788}
                    src="/500.png"
                    alt="500"
                    style={{ maxWidth: 940, width: '100%', height: 'auto' }}
                />
                <p>Oops, it looks like something went wrong.</p>
                <Button
                    margin="2rem 0 0 0"
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    Try Again
                </Button>
            </div>
        </div>
    );
}
